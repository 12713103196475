import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 稽查管理API接口
// --------------------------------------------

/**
 * 获取稽查列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getAudit = async data => {
  return $.get({
    url: 'admin/audit/auditList',
    data,
    isLoad: true,
  })
}

/**
 * 删除稽查单
 * @param {String} ids 稽查id
 */
export const delAudit = async ids => {
  return $.get({
    url: 'admin/audit/AuditDel',
    data: { ids },
  })
}
