<template>
  <div>
    <div v-if='!showType'>
      <SEARCH :searchChange="onCallbackSearch" :list="searchArr"></SEARCH>
      <TABS :tabChange="onCallbackTab" :list="tabsArr"></TABS>
      <TABLE
        :selectChange="onCallbackSelect"
        :buttonChange="onCallbackButton"
        :list="tableArr"
        :data="dataArr"
      ></TABLE>
      <PAGE
        :pageChange="onCallbackPage"
        :limitChange="onCallbackLimit"
        :count="count"
      ></PAGE>
    </div>

    <div v-if="showType === 'showAudit'">
      <NAVBAR :backChange='onCallbackBack' title="查看稽查详情"></NAVBAR>
      <show-audit :id='auditId'></show-audit>
    </div>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import NAVBAR from '@/components/navbar' // 导航
import showAudit from './components/showAudit'
import { updFence } from '@/api/project'
import { getAudit, delAudit } from '@/api/audit'
export default {
  name: 'AuditList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    NAVBAR,
    showAudit,
  },
  data() {
    return {
      searchArr: [
        {
          name: '被查项目',
          type: 'picker',
          options: [],
          key: 'projectUnit',
        },
        {
          name: '状态',
          type: 'picker',
          options: [],
          key: 'type',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增稽查',
          func: '',
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeAudit',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'number',
          label: '稽查编号',
          width: '150',
        },
        {
          prop: 'user_id',
          label: '整改人',
          width: '120',
        },
        {
          prop: '',
          label: '被查单位',
          width: '120',
        },
        {
          prop: '',
          label: '被查项目',
          width: '160',
        },
        {
          prop: 'create_at',
          label: '检查时间',
          width: '150',
        },
        {
          prop: '',
          label: '整改说明',
          width: '200',
        },
        {
          prop: 'audit_date',
          label: '整改期限',
          width: '180',
        },
        {
          prop: '',
          label: '状态',
          width: '100',
          type: 'tag',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '完结',
          func: '',
        },
        {
          type: '',
          name: '查看',
          func: 'openShowAudit',
        },
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: '',
          name: '生成稽查单',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeAudit',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        status: '',
        p_id: '',
      },
      ids: '',
      tabsIndex: 0,
      showType: '', // 是否显示 稽查详情
      auditId: '', // 稽查单id
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      // this.formData.unit_id = this.$store.getters.USER_ID
      getAudit(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          // item.buttons[1].name = item.status === 1 ? '禁用' : '启用'
          // item.buttons[1].type = item.status === 1 ? 'warning' : 'success'

          item.tags = {
            type: item.status === 1 ? 'success' : 'danger',
            name: item.status === 1 ? '已启用' : '已禁用',
          }
        })
        this.dataArr = result.data
      })
    },

    // 查看稽查
    openShowAudit: function(item) {
      this.auditId = String(item.id)
      this.$store.commit('SET_BC', false)
      this.showType = 'showAudit'
    },
    // 关闭稽查
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.showType = ''
      this._loadData()
    },

    // 修改围栏
    editFence: function ({ id, status }) {
      const data = {
        id,
        status: status === 1 ? 0 : 1,
      }
      updFence(data).then((res) => {
        console.log(res, '修改成功')
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除稽查
    removeAudit: function () {
      if (!this.ids) {
        this.$toast('请选择要删除的稽查')
        this.loading()
        return false
      }
      delAudit(this.ids).then((res) => {
        this.$toast(res.message)
        this._loadData()
        this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function (data) {
      const { projectUnit, type } = data
      this.formData.status = type
      this.formData.p_id = projectUnit
      this._loadData()
    },

    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function (index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex]
        .isLoading
    },

    // 收到 表格 内方法 回调
    onCallbackButton: function (item, func) {
      this.ids = item.id
      this[func](item)
    },
    // 收到 选择 回调
    onCallbackSelect: function (ids) {
      this.ids = ids
    },

    // 收到改变页码回调
    onCallbackPage: function (page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function (limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
